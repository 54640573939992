import React from 'react';
import Badge from '../../molecules/Badge/Badge';
import { colors } from '../../styles/atoms/colors';
import Icon from '../../styles/atoms/icons';
import { toCamelCase } from '../../utils/helperFunctions';
import Link from '../../utils/Link';
import { tagColor } from './utils/tagColors';

const AuthorWrapper = ({ author, datePublished, category }) => (
  <div className="author-wrapper">
    <div className="d-flex align-items-center">
      <Icon id="sm-logo" className="source-logo" isImage />
      <p className="author">
        By <span>{author}</span> on {datePublished} in
      </p>
    </div>
    <Link to={`/newsroom?category=${toCamelCase(category)}#articles`}>
      <Badge
        size="sm"
        badgeColor={
          tagColor[category.split(' ').join('').toLowerCase()]
            ? tagColor[category.split(' ').join('').toLowerCase()].bg
            : colors.gray[50]
        }
        textColor={
          tagColor[category.split(' ').join('').toLowerCase()]
            ? tagColor[category.split(' ').join('').toLowerCase()].text
            : colors.gray[800]
        }
      >
        {category}
      </Badge>
    </Link>
  </div>
);

export default AuthorWrapper;
